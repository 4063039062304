@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300;400;700&display=swap");

:root {
  --primary-color: #202020;
  --secondary-color: #ca8019;
  --contact-color: #b9ac92;
  --background: #ffffff;
  --secondary-background: #fffbf7;
  --scrollbar-thumb-color: rgba(185, 172, 146, 0.5);
  --scrollbar-track-color: rgba(185, 172, 146, 0.3);
  --main-heading-size: 3rem;
  --secondary-heading-size: 2.5rem;
  --tertiary-heading-size: 1.75rem;
  --subtitle-size: 1.5rem;
  --main-heading-size-mobile: 2rem;
  --secondary-heading-size-mobile: 1.75rem;
  --tertiary-heading-size-mobile: 1.6rem;
  --subtitle-size-mobile: 1.25rem;
  --button-font-size: 1.1rem;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  color: var(--primary-color);
  scroll-behavior: smooth;
  background-color: transparent;
}

html {
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

html::-webkit-scrollbar {
  width: 1vw;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

html::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

* {
  box-sizing: border-box;
}

::selection {
  background: rgba(185, 172, 146, 0.5);
}

.mainHeading {
  font-size: var(--main-heading-size);
  font-weight: bold;
}

.secondaryHeading {
  font-size: var(--secondary-heading-size);
  font-weight: bold;
}

.tertiaryHeading {
  font-size: var(--tertiary-heading-size);
  font-weight: bold;
}

.subtitle {
  font-size: var(--subtitle-size);
}

.drawerItem:hover {
  background-color: #dbcbbd;
  color: #290001;
}

.drawerItemIcon {
  color: #290001;
}

.mcImages {
  border-radius: 50%;
}

.mcImageBorder {
  position: relative;
  border-radius: 50%;
  height: 250px;
  width: 250px;
}

.obImageBorderColor {
  outline: 3px solid #fbc6a4;
  outline-offset: 4px;
}

.mcImageBorderColor {
  outline: 3px solid #ecd662;
  outline-offset: 4px;
}

/* Styled link */
.styledLink {
  position: relative;
  color: inherit;
}

.styledLink:focus-within {
  text-decoration: none;
}

.styledLink::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

.styledLink:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.MuiFormControlLabel {
  font-size: 0.5rem;
}

.styledHeading {
  position: relative;
  display: inline-block;
}

.styledHeading::before,
.styledHeading::after {
  content: "";
  height: 100%;
  display: block;
  position: absolute;
}

.styledHeading::before {
  background: #ffffff;
  z-index: -1;
  left: -30px;
  right: -30px;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 30%;
}

.styledHeading::after {
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgba(244, 234, 178, 0.2),
    rgba(233, 174, 26, 0.1),
    rgb(255, 255, 255)
  );
  width: 80vw;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -2;
}

.animateNavLogoBanner {
  animation-name: logo-animate;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

@keyframes logo-animate {
  0% {
    transform: translateY(-20vw);
  }

  100% {
    transform: translateY(0);
  }
}

.timesButtonAnimation {
  transition: transform 0.25s;
}

.timesButtonAnimation:hover {
  transform: rotate(180deg);
}

.wavingHandAnimation {
  font-size: 0.1rem;
  animation-name: wave;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes wave {
  0% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(20deg);
  }
}

.blinkingDotAnimation {
  font-weight: bold;
  margin-left: 2px;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  transition: opacity 500ms;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.counter {
  counter-reset: site-counter;
}

.counterSection {
  position: relative;
}

.counterSection::before {
  counter-increment: site-counter;
  content: counter(site-counter) " ";
  font-size: 0.8rem;
  position: absolute;
  left: -2rem;
  top: 0.2em;
  background-color: #ff8e01;
  color: #fff;
  font-weight: 700;
  height: 1.7em;
  width: 1.7em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divStylingSecondary {
  border-bottom: 5px solid var(--secondary-color);
  padding-bottom: 1px;
}

.divStylingContact {
  border-bottom: 5px solid var(--contact-color);
  padding-bottom: 1px;
}

/* Scroll to top */
.scrollToTopContainer {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: var(--primary-color);
  height: 50px;
  width: 40px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 17px 20px rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.scrollToTop {
  color: #fff;
  font-size: 1.5rem;
}

/* The waves at the top of the footer */
.waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.waves svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 36px;
}

.waves .shape-fill {
  fill: #ffffff;
}

/* Top tilt - contact */

.top-tilt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.top-tilt svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 30px;
  transform: rotateY(180deg);
}

.top-tilt .shape-fill {
  fill: #ffffff;
}

/* Bottom waves - contact */
.wave-bottom-contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave-bottom-contact svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 59px;
  transform: rotateY(180deg);
}

.wave-bottom-contact .shape-fill {
  fill: #ffffff;
}

/* Arrow at the top of the testimonial section */
.triangle-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.triangle-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 67px;
}

.triangle-top .shape-fill {
  fill: #ffffff;
}

@media only screen and (max-width: 900px) {
  .my-masonry-grid_column_testimonials {
    padding-left: 20px; /* gutter size */
    padding-right: 20px;
    background-clip: padding-box;
  }

  .styledHeading::after {
    width: 100vw;
  }

  .mainHeading {
    font-size: var(--main-heading-size-mobile);
  }

  .secondaryHeading {
    font-size: var(--secondary-heading-size-mobile);
  }

  .tertiaryHeading {
    font-size: var(--tertiary-heading-size-mobile);
  }

  .subtitle {
    font-size: var(--subtitle-size-mobile);
  }

  .hideEsc {
    display: none;
  }
}
