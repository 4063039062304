@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300;400;700&display=swap);
:root {
  --primary-color: #202020;
  --secondary-color: #ca8019;
  --contact-color: #b9ac92;
  --background: #ffffff;
  --secondary-background: #fffbf7;
  --scrollbar-thumb-color: rgba(185, 172, 146, 0.5);
  --scrollbar-track-color: rgba(185, 172, 146, 0.3);
  --main-heading-size: 3rem;
  --secondary-heading-size: 2.5rem;
  --tertiary-heading-size: 1.75rem;
  --subtitle-size: 1.5rem;
  --main-heading-size-mobile: 2rem;
  --secondary-heading-size-mobile: 1.75rem;
  --tertiary-heading-size-mobile: 1.6rem;
  --subtitle-size-mobile: 1.25rem;
  --button-font-size: 1.1rem;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  color: var(--primary-color);
  scroll-behavior: smooth;
  background-color: transparent;
}

html {
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

html::-webkit-scrollbar {
  width: 1vw;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

html::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

* {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

::-moz-selection {
  background: rgba(185, 172, 146, 0.5);
}

::selection {
  background: rgba(185, 172, 146, 0.5);
}

.mainHeading {
  font-size: var(--main-heading-size);
  font-weight: bold;
}

.secondaryHeading {
  font-size: var(--secondary-heading-size);
  font-weight: bold;
}

.tertiaryHeading {
  font-size: var(--tertiary-heading-size);
  font-weight: bold;
}

.subtitle {
  font-size: var(--subtitle-size);
}

.drawerItem:hover {
  background-color: #dbcbbd;
  color: #290001;
}

.drawerItemIcon {
  color: #290001;
}

.mcImages {
  border-radius: 50%;
}

.mcImageBorder {
  position: relative;
  border-radius: 50%;
  height: 250px;
  width: 250px;
}

.obImageBorderColor {
  outline: 3px solid #fbc6a4;
  outline-offset: 4px;
}

.mcImageBorderColor {
  outline: 3px solid #ecd662;
  outline-offset: 4px;
}

/* Styled link */
.styledLink {
  position: relative;
  color: inherit;
}

.styledLink:focus-within {
  text-decoration: none;
}

.styledLink::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 2px;
  background: currentColor;
  -webkit-transform: scaleX(0);
     -moz-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
     -moz-transform-origin: right;
          transform-origin: right;
  -webkit-transition: -webkit-transform 250ms ease-in;
  transition: -webkit-transform 250ms ease-in;
  -moz-transition: transform 250ms ease-in, -moz-transform 250ms ease-in;
  transition: transform 250ms ease-in;
  transition: transform 250ms ease-in, -webkit-transform 250ms ease-in, -moz-transform 250ms ease-in;
}

.styledLink:hover::after {
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
          transform-origin: left;
  -webkit-transform: scaleX(1);
     -moz-transform: scaleX(1);
          transform: scaleX(1);
}

.MuiFormControlLabel {
  font-size: 0.5rem;
}

.styledHeading {
  position: relative;
  display: inline-block;
}

.styledHeading::before,
.styledHeading::after {
  content: "";
  height: 100%;
  display: block;
  position: absolute;
}

.styledHeading::before {
  background: #ffffff;
  z-index: -1;
  left: -30px;
  right: -30px;
  border-top-right-radius: 30%;
  border-bottom-left-radius: 30%;
}

.styledHeading::after {
  background-image: -webkit-linear-gradient(
    left,
    rgb(255, 255, 255),
    rgba(244, 234, 178, 0.2),
    rgba(233, 174, 26, 0.1),
    rgb(255, 255, 255)
  );
  background-image: -moz-linear-gradient(
    left,
    rgb(255, 255, 255),
    rgba(244, 234, 178, 0.2),
    rgba(233, 174, 26, 0.1),
    rgb(255, 255, 255)
  );
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgba(244, 234, 178, 0.2),
    rgba(233, 174, 26, 0.1),
    rgb(255, 255, 255)
  );
  width: 80vw;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -2;
}

.animateNavLogoBanner {
  -webkit-animation-name: logo-animate;
     -moz-animation-name: logo-animate;
          animation-name: logo-animate;
  -webkit-animation-duration: 0.5s;
     -moz-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
     -moz-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}

@-webkit-keyframes logo-animate {
  0% {
    -webkit-transform: translateY(-20vw);
            transform: translateY(-20vw);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-moz-keyframes logo-animate {
  0% {
    -moz-transform: translateY(-20vw);
         transform: translateY(-20vw);
  }

  100% {
    -moz-transform: translateY(0);
         transform: translateY(0);
  }
}

@keyframes logo-animate {
  0% {
    -webkit-transform: translateY(-20vw);
       -moz-transform: translateY(-20vw);
            transform: translateY(-20vw);
  }

  100% {
    -webkit-transform: translateY(0);
       -moz-transform: translateY(0);
            transform: translateY(0);
  }
}

.timesButtonAnimation {
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -moz-transition: transform 0.25s, -moz-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s, -moz-transform 0.25s;
}

.timesButtonAnimation:hover {
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}

.wavingHandAnimation {
  font-size: 0.1rem;
  -webkit-animation-name: wave;
     -moz-animation-name: wave;
          animation-name: wave;
  -webkit-animation-duration: 1s;
     -moz-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
     -moz-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }

  100% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@-moz-keyframes wave {
  0% {
    -moz-transform: rotate(20deg);
         transform: rotate(20deg);
  }

  50% {
    -moz-transform: rotate(-20deg);
         transform: rotate(-20deg);
  }

  100% {
    -moz-transform: rotate(20deg);
         transform: rotate(20deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotate(20deg);
       -moz-transform: rotate(20deg);
            transform: rotate(20deg);
  }

  50% {
    -webkit-transform: rotate(-20deg);
       -moz-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }

  100% {
    -webkit-transform: rotate(20deg);
       -moz-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

.blinkingDotAnimation {
  font-weight: bold;
  margin-left: 2px;
  -webkit-animation-name: blink;
     -moz-animation-name: blink;
          animation-name: blink;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
     -moz-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  transition: opacity 500ms;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.counter {
  counter-reset: site-counter;
}

.counterSection {
  position: relative;
}

.counterSection::before {
  counter-increment: site-counter;
  content: counter(site-counter) " ";
  font-size: 0.8rem;
  position: absolute;
  left: -2rem;
  top: 0.2em;
  background-color: #ff8e01;
  color: #fff;
  font-weight: 700;
  height: 1.7em;
  width: 1.7em;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
}

.divStylingSecondary {
  border-bottom: 5px solid var(--secondary-color);
  padding-bottom: 1px;
}

.divStylingContact {
  border-bottom: 5px solid var(--contact-color);
  padding-bottom: 1px;
}

/* Scroll to top */
.scrollToTopContainer {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: var(--primary-color);
  height: 50px;
  width: 40px;
  padding: 10px;
  border-radius: 10px;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
  cursor: pointer;
  box-shadow: 0 17px 20px rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.scrollToTop {
  color: #fff;
  font-size: 1.5rem;
}

/* The waves at the top of the footer */
.waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.waves svg {
  position: relative;
  display: block;
  width: -moz-calc(100% + 1.3px);
  width: calc(100% + 1.3px);
  height: 36px;
}

.waves .shape-fill {
  fill: #ffffff;
}

/* Top tilt - contact */

.top-tilt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.top-tilt svg {
  position: relative;
  display: block;
  width: -moz-calc(100% + 1.3px);
  width: calc(100% + 1.3px);
  height: 30px;
  -webkit-transform: rotateY(180deg);
     -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.top-tilt .shape-fill {
  fill: #ffffff;
}

/* Bottom waves - contact */
.wave-bottom-contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
          transform: rotate(180deg);
}

.wave-bottom-contact svg {
  position: relative;
  display: block;
  width: -moz-calc(100% + 1.3px);
  width: calc(100% + 1.3px);
  height: 59px;
  -webkit-transform: rotateY(180deg);
     -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.wave-bottom-contact .shape-fill {
  fill: #ffffff;
}

/* Arrow at the top of the testimonial section */
.triangle-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.triangle-top svg {
  position: relative;
  display: block;
  width: -moz-calc(100% + 1.3px);
  width: calc(100% + 1.3px);
  height: 67px;
}

.triangle-top .shape-fill {
  fill: #ffffff;
}

@media only screen and (max-width: 900px) {
  .my-masonry-grid_column_testimonials {
    padding-left: 20px; /* gutter size */
    padding-right: 20px;
    background-clip: padding-box;
  }

  .styledHeading::after {
    width: 100vw;
  }

  .mainHeading {
    font-size: var(--main-heading-size-mobile);
  }

  .secondaryHeading {
    font-size: var(--secondary-heading-size-mobile);
  }

  .tertiaryHeading {
    font-size: var(--tertiary-heading-size-mobile);
  }

  .subtitle {
    font-size: var(--subtitle-size-mobile);
  }

  .hideEsc {
    display: none;
  }
}

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
       justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  -moz-box-flex: 1;
       flex: 1 1 auto;
  padding: 6px;
}

.Toastify--animate {
  -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
     -moz-animation-duration: 0.7s;
          animation-duration: 0.7s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@-moz-keyframes Toastify__trackProgress {
  0% {
    -moz-transform: scaleX(1);
         transform: scaleX(1);
  }
  100% {
    -moz-transform: scaleX(0);
         transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
       -moz-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
       -moz-transform: scaleX(0);
            transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transform-origin: left;
     -moz-transform-origin: left;
          transform-origin: left;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
     -moz-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -moz-transition: transform 0.2s, -moz-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s, -moz-transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform-origin: right;
     -moz-transform-origin: right;
          transform-origin: right;
}
.Toastify__progress-bar--default {
  background: -webkit-linear-gradient(left, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  background: -moz-linear-gradient(left, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@-webkit-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-moz-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -moz-transform: translate3d(3000px, 0, 0);
         transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -moz-transform: translate3d(-25px, 0, 0);
         transform: translate3d(-25px, 0, 0);
  }
  75% {
    -moz-transform: translate3d(10px, 0, 0);
         transform: translate3d(10px, 0, 0);
  }
  90% {
    -moz-transform: translate3d(-5px, 0, 0);
         transform: translate3d(-5px, 0, 0);
  }
  to {
    -moz-transform: none;
         transform: none;
  }
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
       -moz-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
       -moz-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
       -moz-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
       -moz-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
       -moz-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@-moz-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -moz-transform: translate3d(-20px, 0, 0);
         transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -moz-transform: translate3d(2000px, 0, 0);
         transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
       -moz-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
       -moz-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-moz-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -moz-transform: translate3d(-3000px, 0, 0);
         transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -moz-transform: translate3d(25px, 0, 0);
         transform: translate3d(25px, 0, 0);
  }
  75% {
    -moz-transform: translate3d(-10px, 0, 0);
         transform: translate3d(-10px, 0, 0);
  }
  90% {
    -moz-transform: translate3d(5px, 0, 0);
         transform: translate3d(5px, 0, 0);
  }
  to {
    -moz-transform: none;
         transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
       -moz-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
       -moz-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
       -moz-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
       -moz-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
       -moz-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@-moz-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -moz-transform: translate3d(20px, 0, 0);
         transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -moz-transform: translate3d(-2000px, 0, 0);
         transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
       -moz-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
       -moz-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -moz-transform: translate3d(0, 3000px, 0);
         transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -moz-transform: translate3d(0, -20px, 0);
         transform: translate3d(0, -20px, 0);
  }
  75% {
    -moz-transform: translate3d(0, 10px, 0);
         transform: translate3d(0, 10px, 0);
  }
  90% {
    -moz-transform: translate3d(0, -5px, 0);
         transform: translate3d(0, -5px, 0);
  }
  to {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
       -moz-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
       -moz-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
       -moz-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
       -moz-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@-moz-keyframes Toastify__bounceOutUp {
  20% {
    -moz-transform: translate3d(0, -10px, 0);
         transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -moz-transform: translate3d(0, 20px, 0);
         transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -moz-transform: translate3d(0, -2000px, 0);
         transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
       -moz-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
       -moz-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
       -moz-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-moz-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, -3000px, 0);
         transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -moz-transform: translate3d(0, 25px, 0);
         transform: translate3d(0, 25px, 0);
  }
  75% {
    -moz-transform: translate3d(0, -10px, 0);
         transform: translate3d(0, -10px, 0);
  }
  90% {
    -moz-transform: translate3d(0, 5px, 0);
         transform: translate3d(0, 5px, 0);
  }
  to {
    -moz-transform: none;
         transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
       -moz-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
       -moz-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
       -moz-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
       -moz-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
       -moz-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes Toastify__bounceOutDown {
  20% {
    -moz-transform: translate3d(0, 10px, 0);
         transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -moz-transform: translate3d(0, -20px, 0);
         transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -moz-transform: translate3d(0, 2000px, 0);
         transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
       -moz-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
       -moz-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
       -moz-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
     -moz-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
     -moz-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
     -moz-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
     -moz-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
     -moz-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
     -moz-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
     -moz-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
     -moz-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -moz-transform: scale3d(0.3, 0.3, 0.3);
         transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
       -moz-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -moz-transform: scale3d(0.3, 0.3, 0.3);
         transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
       -moz-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
     -moz-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
     -moz-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@-moz-keyframes Toastify__flipIn {
  from {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
         transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -moz-animation-timing-function: ease-in;
         animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
         transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -moz-animation-timing-function: ease-in;
         animation-timing-function: ease-in;
  }
  60% {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
         transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
         transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -moz-transform: perspective(400px);
         transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
       -moz-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
       -moz-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
       -moz-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes Toastify__flipOut {
  from {
    -moz-transform: perspective(400px);
         transform: perspective(400px);
  }
  30% {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
         transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
         transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
       -moz-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
     -moz-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
     -moz-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes Toastify__slideInRight {
  from {
    -moz-transform: translate3d(110%, 0, 0);
         transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
       -moz-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes Toastify__slideInLeft {
  from {
    -moz-transform: translate3d(-110%, 0, 0);
         transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
       -moz-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes Toastify__slideInUp {
  from {
    -moz-transform: translate3d(0, 110%, 0);
         transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
       -moz-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes Toastify__slideInDown {
  from {
    -moz-transform: translate3d(0, -110%, 0);
         transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
       -moz-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@-moz-keyframes Toastify__slideOutRight {
  from {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -moz-transform: translate3d(110%, 0, 0);
         transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
       -moz-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@-moz-keyframes Toastify__slideOutLeft {
  from {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -moz-transform: translate3d(-110%, 0, 0);
         transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
       -moz-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@-moz-keyframes Toastify__slideOutDown {
  from {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -moz-transform: translate3d(0, 500px, 0);
         transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
       -moz-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
@-moz-keyframes Toastify__slideOutUp {
  from {
    -moz-transform: translate3d(0, 0, 0);
         transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -moz-transform: translate3d(0, -500px, 0);
         transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
       -moz-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
     -moz-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
     -moz-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
     -moz-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
     -moz-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
     -moz-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
     -moz-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
     -moz-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
     -moz-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */
